import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Geocode from 'react-geocode';
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import { connect } from "react-redux";
import "../../assets/scss/datatables.scss";
import { MDBDataTable } from "mdbreact";
import ReactApexChart from 'react-apexcharts';
import Select from 'react-select';
import httpService from '../../services/httpService';
import { getJwt } from '../../services/authService';
import mark from '../../assets/images/markerRed.png'
import '../../assets/css/maps.css'

const Marker = ({ children }) => children;

const Lokasi = (props) => {
    const mapRef = useRef();
    const [Coord, setCoord] = useState([]);
    const [List, setList] = useState([]);
    const [bounds, setBounds] = useState(null);
    const [zoom, setZoom] = useState(5);
    const [location, setLoc] = useState('province')
    const [optionsBar, setOptBar] = useState({});
    const [seriesBar, setSeriesBar] = useState([]);
    const [height, setHeight] = useState('350')
    const [province, setProvince] = useState([]);
    const [regency, setRegency] = useState([]);
    const [district, setDistrict] = useState([]);
    const [village, setVillage] = useState([]);
    const [sortRegency, setSortRegency] = useState([]);
    const [sortDistrict, setSortDistrict] = useState([]);
    const [sortVillage, setSortVillage] = useState([]);
    const [chooseProvince, setChooseProvince] = useState('');
    const [chooseRegency, setChooseRegency] = useState('');
    const [chooseDistrict, setChooseDistrict] = useState('');
    const [chooseVillage, setChooseVillage] = useState('');
    let selectRefProvince = null
    let selectRefRegency = null
    let selectRefDistrict = null
    let selectRefVillage = null

    const handleLokasi = (e) => {
        const val = e.target.value
        setLoc(val)
        getDataBar(val);
    }

    const getLokasi = () => {
        const apiProvince = process.env.REACT_APP_API_URL + 'location/province';
        const apiRegency = process.env.REACT_APP_API_URL + 'location/regency';
        const apiDistrict = process.env.REACT_APP_API_URL + 'location/district';
        const apiVilage = process.env.REACT_APP_API_URL + 'location/village';
        httpService.get(apiProvince, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const prov = res.data.data
            const listProvince = [];
            prov.forEach(item => {
                listProvince.push({
                    id: item.id,
                    value: item.name,
                    label: item.name
                })
            })
            setProvince(listProvince)
        })
        httpService.get(apiRegency, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const reg = res.data.data
            const listRegency = [];
            reg.forEach(item => {
                listRegency.push({
                    id: item.id,
                    id_province: item.province_id,
                    value: item.name,
                    label: item.name
                })
            })
            setRegency(listRegency)
        })
        httpService.get(apiDistrict, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const dist = res.data.data
            const listDistrict = [];
            dist.forEach(item => {
                listDistrict.push({
                    id: item.id,
                    id_regency: item.regency_id,
                    value: item.name,
                    label: item.name
                })
            })
            setDistrict(listDistrict)
        })
        httpService.get(apiVilage, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const vill = res.data.data
            const listVillage = [];
            vill.forEach(item => {
                listVillage.push({
                    id: item.id,
                    id_district: item.district_id,
                    value: item.name,
                    label: item.name
                })
            })
            setVillage(listVillage)
        })
    } 

    const handleProvince = (e) => {
        const filterRegency = [];
        if (e !== null) {
            regency.forEach(a => {
                if (a.id_province === e.id) {
                    filterRegency.push(a)
                }
            })
            setSortRegency(filterRegency)
            setChooseProvince(e.value)
        } else {
            setSortRegency(filterRegency)
        }
    }

    const handleRegency = (e) => {
        const filterDistrict = [];
        if (e !== null) {
            district.forEach(a => {
                if (a.id_regency === e.id) {
                    filterDistrict.push(a)
                }
            })
            setSortDistrict(filterDistrict)
            setChooseRegency(e.value)
        } else {
            setSortDistrict(filterDistrict)
        }
    }

    const handleDistrict = (e) => {
        const filterVillage = [];
        if (e !== null) {
            village.forEach(a => {
                if (a.id_district === e.id) {
                    filterVillage.push(a)
                }
            })
            setSortVillage(filterVillage)
            setChooseDistrict(e.value)
        } else {
            setSortVillage(filterVillage)
        }
    }

    const handleVillage = (e) => {
        if (e !== null) {
            setChooseVillage(e.value)
        }
    }

    const submitFilter = () => {
        getList(chooseProvince, chooseRegency, chooseDistrict, chooseVillage)
    }

    const clearValue = () => {
        selectRefProvince.select.clearValue()
        selectRefRegency.select.clearValue()
        selectRefDistrict.select.clearValue()
        selectRefVillage.select.clearValue()
        setChooseProvince('')
        setChooseRegency('')
        setChooseDistrict('')
        setChooseVillage('')
        getList('', '', '', '')
    };

    const getDataBar = (loc) => {
        let lokasi;
        if (!loc) {
            lokasi = location
        } else {
            lokasi = loc
        }
        const apiUrl = process.env.REACT_APP_API_URL + 'unitUsaha/location/total?location='+lokasi+'&limit=10'
        httpService.get(apiUrl, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const data_lokasi = res.data.data
            const category_lokasi = [];
            const count_lokasi = [];
            data_lokasi.forEach(item => {
                category_lokasi.push(item.name);
                count_lokasi.push(item.total);
            })
            if (data_lokasi.length === 1) {
                setHeight('100')
            } else if (data_lokasi.length < 5) {
                setHeight('250')
            } else if (data_lokasi.length > 5) {
                setHeight('350')
            }
            const options = {
                chart: {
                    toolbar: {
                        show: false,
                    }
                },
                colors: ['#4D895E'],
                plotOptions: {
                    bar: {
                        columnWidth: '5%',
                        endingShape: 'rounded',
                        dataLabels: {
                            show: false
                        },
                        horizontal: true,
                    }
                },
                legend: {
                    show: false
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: '#4D895E',
                    row: {
                        colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    }
                },
                stroke: {
                    show: true,
                    width: 1.5,
                    colors: ['#fff']
                },
                xaxis: {
                    categories: category_lokasi,
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    }
                }
            }
            const series = [{
                name: 'Total',
                data: count_lokasi
            }]

            setOptBar(options)
            setSeriesBar(series)
        })
    }

    const getList = (prov, reg, dist, vill) => {
        let provinsi, kota_kab, kec, desa
        if (prov === undefined) {
            provinsi = chooseProvince
            kota_kab = chooseRegency
            kec = chooseDistrict
            desa = chooseVillage
        } else {
            provinsi = prov
            kota_kab = reg
            kec = dist
            desa = vill
        }
        const list = {
            columns: [
                {
                  label: "No",
                  field: "no",
                  sort: "asc",
                  width: 150
                },
                {
                  label: "Unit Usaha",
                  field: "unit_usaha",
                  sort: "asc",
                  width: 270
                },
                {
                  label: "Anggota",
                  field: "anggota",
                  sort: "asc",
                  width: 200
                },
                {
                    label: "Provinsi",
                    field: "provinsi",
                    sort: "asc",
                    width: 100
                  },
                {
                  label: "Kota/Kabupaten",
                  field: "kota_kab",
                  sort: "asc",
                  width: 100
                },
                {
                    label: "Kecamatan",
                    field: "kec",
                    sort: "asc",
                    width: 100
                },
                {
                    label: "Desa",
                    field: "desa",
                    sort: "asc",
                    width: 100
                }
            ],
            rows: []
        }
        const apiUrl = process.env.REACT_APP_API_URL + 'unitUsaha?province='+provinsi+'&regency='+kota_kab+'&districts='+kec+'&village='+desa;
        httpService.get(apiUrl, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const data = res.data.data
            data.forEach((item, index) => {
                list.rows.push({
                    no: index+1,
                    unit_usaha: item.name,
                    anggota: item.member.length,
                    provinsi: item.province !== null ? item.province : "-",
                    kota_kab: item.regency !== null ? item.regency : "-",
                    kec: item.city !== null ? item.city : "-",
                    desa: item.districts !== null ? item.districts : "-",
                })
            })
            setList(list)
        })
    }

    const getDataMap = () => {
        Geocode.setApiKey("AIzaSyB_HIpyugMy5hwgEoWV7_iFlBLf5obJnOs");
        Geocode.setLanguage("id");
        Geocode.setRegion("id");
        const PromiseArr = []
        const address = [];
        const apiUrl = process.env.REACT_APP_API_URL + 'unitUsaha'
        httpService.get(apiUrl, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const data = res.data.data
            data.forEach(item => {
                if (item.address !== null) {
                    address.push(item.address);
                }
            })
            address.forEach(item => {
                PromiseArr.push(
                    Geocode.fromAddress(item).then(
                        (response) => 
                            new Promise(resolve => resolve({
                                name: item,
                                lat: response.results[0].geometry.location.lat, 
                                long: response.results[0].geometry.location.lng
                            })),
                        (error) => {
                            console.error(error);
                        }
                    )
                )
            })
            Promise.all(PromiseArr).then(res => {
                setCoord(res);
            })
        })
    }

    const getAllAPI = () => {
        getList()
        getDataMap()
        getDataBar()
        getLokasi()
    }

    useEffect(() => {
        getAllAPI()
        // eslint-disable-next-line
    }, [])

    const crimes = Coord && Coord.length > 0 ? Coord : [];
    const points = crimes.map(crime => ({
        type: "Feature",
        properties: { cluster: false, crimeId: crime.name },
        geometry: {
        type: "Point",
        coordinates: [
            parseFloat(crime.long),
            parseFloat(crime.lat)
        ]
        }
    }));

    const { clusters, supercluster } = useSupercluster({
        points,
        bounds,
        zoom,
        options: { radius: 75, maxZoom: 20 }
    });

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Unit Usaha" breadcrumbItem="Lokasi" />
                    <Row>
                        <Col md="8">
                            <Card>
                                <CardBody>
                                    <h6>Maps</h6>
                                    <div
                                        id="gmaps-markers"
                                        className="gmaps"
                                        style={{ position: "relative", height: '25rem'}}
                                        >
                                        <GoogleMapReact
                                            bootstrapURLKeys={{ key: 'AIzaSyB_HIpyugMy5hwgEoWV7_iFlBLf5obJnOs' }}
                                            defaultCenter={{ lat: -0.789275, lng: 113.921327 }}
                                            defaultZoom={5}
                                            yesIWantToUseGoogleMapApiInternals
                                            onGoogleApiLoaded={({ map }) => {
                                            mapRef.current = map;
                                            }}
                                            onChange={({ zoom, bounds }) => {
                                            setZoom(zoom);
                                            setBounds([
                                                bounds.nw.lng,
                                                bounds.se.lat,
                                                bounds.se.lng,
                                                bounds.nw.lat
                                            ]);
                                            }}
                                        >
                                            {clusters.map(cluster => {
                                            const [longitude, latitude] = cluster.geometry.coordinates;
                                            const {
                                                cluster: isCluster,
                                                point_count: pointCount
                                            } = cluster.properties;

                                            if (isCluster) {
                                                return (
                                                <Marker
                                                    key={`cluster-${cluster.id}`}
                                                    lat={latitude}
                                                    lng={longitude}
                                                >
                                                    <div
                                                    className="cluster-marker"
                                                    style={{
                                                        width: `${10 + (pointCount / points.length) * 20}px`,
                                                        height: `${10 + (pointCount / points.length) * 20}px`
                                                    }}
                                                    onClick={() => {
                                                        const expansionZoom = Math.min(
                                                        supercluster.getClusterExpansionZoom(cluster.id),
                                                        20
                                                        );
                                                        mapRef.current.setZoom(expansionZoom);
                                                        mapRef.current.panTo({ lat: latitude, lng: longitude });
                                                    }}
                                                    >
                                                    {pointCount}
                                                    </div>
                                                </Marker>
                                                );
                                            }

                                            return (
                                                <Marker
                                                key={`crime-${cluster.properties.crimeId}`}
                                                lat={latitude}
                                                lng={longitude}
                                                >
                                                <button className="btn crime-marker">
                                                    <img src={mark} alt="marker" />
                                                </button>
                                                </Marker>
                                            );
                                            })}
                                        </GoogleMapReact>
                                        </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="4">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <h6 className="mt-2">Unit Usaha Terbanyak</h6>
                                        </Col>
                                        <Col>
                                            <select className="form-control" onChange={handleLokasi}>
                                                <option value="province">Provinsi</option>
                                                <option value="regency">Kabupaten</option>
                                                <option value="city">Kota</option>
                                                <option value="districts">Kecamatan</option>
                                            </select>
                                        </Col>
                                    </Row>
                                    <div className="mt-2">
                                        <ReactApexChart options={optionsBar} series={seriesBar} type="bar" height={height} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <h6>List Unit Usaha</h6>
                                    <div className="d-flex">
                                        <span className="mt-2">Lokasi</span>
                                        <Select
                                            ref={ref => selectRefProvince = ref}
                                            className="col-md-2"
                                            classNamePrefix="select"
                                            defaultValue
                                            placeholder="Provinsi"
                                            isClearable={false}
                                            isSearchable={true}
                                            options={province}
                                            onChange={handleProvince}
                                        />
                                        <Select
                                            ref={ref => selectRefRegency = ref}
                                            className="col-md-2"
                                            classNamePrefix="select"
                                            defaultValue
                                            placeholder="Kota/Kabupaten"
                                            isClearable={false}
                                            isSearchable={true}
                                            options={sortRegency}
                                            onChange={handleRegency}
                                        />
                                        <Select
                                            ref={ref => selectRefDistrict = ref}
                                            className="col-md-2"
                                            classNamePrefix="select"
                                            defaultValue
                                            placeholder="Kecamatan"
                                            isClearable={false}
                                            isSearchable={true}
                                            options={sortDistrict}
                                            onChange={handleDistrict}
                                        />
                                        <Select
                                            ref={ref => selectRefVillage = ref}
                                            className="col-md-2"
                                            classNamePrefix="select"
                                            defaultValue
                                            placeholder="Desa"
                                            isClearable={false}
                                            isSearchable={true}
                                            options={sortVillage}
                                            onChange={handleVillage}
                                        />
                                        <button className="btn btn-primary ml-3" onClick={submitFilter}>Apply</button>
                                        <button className="btn btn-danger ml-3" onClick={clearValue}>Reset</button>
                                    </div>
                                    <div className="mt-3">
                                        <MDBDataTable responsive data={List} />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default connect(
    null,
    {}
  )(
   Lokasi
  );