import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import trendImg from '../../assets/images/data_trend.png';
import httpService from '../../services/httpService';
import { getJwt } from '../../services/authService';
import Datetime from "react-datetime";
import 'react-datetime/css/react-datetime.css';
import Transaction from './Chart/Transaction';
import UserUnit from './Chart/UserUnit';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            total_verif_user: 0,
            total_verif_unit: 0,
            total_user: 0,
            total_unit: 0,
            dataTransaksi: [],
            dataUserUnit: [],
            year: new Date().getFullYear(),
            currentYear: new Date(),
            currentYearTrans: new Date(),
        };
        this.handleYear = this.handleYear.bind(this);
        this.handleYearTrans = this.handleYearTrans.bind(this);
    }

    async getCount() {
        const url = process.env.REACT_APP_API_URL + 'history/data'
        httpService.get(url, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            var response = res.data.data
            this.setState({
                total_user: response[0].data,
                total_unit: response[1].data,
                total_verif_user: response[2].data,
                total_verif_unit: response[3].data
            })
        })
    }

    async getDataTransaction(year) {
        const url = process.env.REACT_APP_API_URL + 'history_transaction/total?year='+year
        httpService.get(url, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            this.setState({ dataTransaksi: res.data.data })
        })
        .catch(err => {
            if (err.response.data.message) {
                toast.error(err.response.data.message)
            } else {
                toast.error('Network Error!!')
            }
        })
    }

    async getDataUserUnit(year) {
        const url = process.env.REACT_APP_API_URL + 'history/user_unit_usaha?year='+year
        httpService.get(url, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            this.setState({ dataUserUnit: res.data.data });
        })
        .catch(err => {
            if (err.response.data.message) {
                toast.error(err.response.data.message)
            } else {
                toast.error('Network Error!!')
            }
        })
    }

    handleYear(date) {
        var year = date.year();
        this.setState({ currentYear: date })
        this.getDataUserUnit(year)
    }

    handleYearTrans(date) {
        var year = date.year();
        this.setState({ currentYearTrans: date })
        this.getDataTransaction(year)
    }

    async componentDidMount() {
        if (!getJwt()) {
            this.props.history.push('/login')
        } else {
            await this.getCount()
            await this.getDataTransaction(this.state.year)
            await this.getDataUserUnit(this.state.year)
        }
    }

    render() {  
        const { total_user, total_unit, total_verif_user, total_verif_unit, dataTransaksi, dataUserUnit } = this.state
        return (
            <React.Fragment>
                <ToastContainer />
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Bu Lurah" breadcrumbItem="Dashboard" />
                        <Row>
                            <Col md="8">
                                <Row>
                                    <Col md='12'>
                                        <Card className="bg-soft-primary" style={{ color: '#4D895E'}}>
                                            <CardBody>
                                                <Row>
                                                    <Col md="8" className="mt-3">
                                                        <span style={{fontSize: '1rem', fontWeight: 'bold'}}>Hallo, Admin!</span>
                                                        <p style={{fontSize: '1rem'}}>Selamat datang di Dashboard Bu Lurah</p>
                                                    </Col>
                                                    <Col style={{textAlign: 'right'}}>
                                                        <img src={trendImg} className="img-fluid" alt="" style={{height: "5rem"}} />
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <div className="mt-5">
                                                            <h6>User Terdaftar</h6>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="text-center">
                                                            <div className="avatar-lg mx-auto">
                                                                <span className="avatar-title rounded-circle font-size-24 bg-soft-primary" style={{color: '#6CBC83', padding: '1rem'}}>{total_user}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col>
                                        <Card>
                                            <CardBody>
                                                <Row>
                                                    <Col>
                                                        <div className="mt-5">
                                                            <h6>Unit Usaha Terdaftar</h6>
                                                        </div>
                                                    </Col>
                                                    <Col>
                                                        <div className="text-center">
                                                            <div className="avatar-lg mx-auto">
                                                                <span className="avatar-title rounded-circle font-size-24 bg-soft-primary" style={{color: '#6CBC83', padding: '1rem'}}>{total_unit}</span>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="4">
                                <Row>
                                    <Col md="12">
                                        <Card>
                                            <CardBody>
                                                <h6>Permintaan Verifikasi User</h6>
                                                <div className="bg-soft-primary" style={{padding: '1rem', color: '#6CBC83', borderRadius: '5px'}}>
                                                    <Row>
                                                        <Col md="4">
                                                            <div className="text-center">
                                                                <span style={{fontWeight: 'bold', fontSize: '20px'}}>{total_verif_user}</span>
                                                            </div> 
                                                        </Col>
                                                        <Col md="8">
                                                            <div className="mt-1">Permintaan Verifikasi</div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md="12">
                                        <Card>
                                            <CardBody>
                                                <h6>Permintaan Verifikasi Unit Usaha</h6>
                                                <div style={{backgroundColor: '#F3F3F3', padding: '1rem', color: '#A4A4A4', borderRadius: '5px'}}>
                                                    <Row>
                                                        <Col md="4">
                                                            <div className="text-center">
                                                                <span style={{fontWeight: 'bold', fontSize: '20px'}}>{total_verif_unit}</span>
                                                            </div> 
                                                        </Col>
                                                        <Col md="8">
                                                            <div className="mt-1">Permintaan Verifikasi</div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div className="row">
                                            <div className="col-md-9">
                                                <h6>Transaksi</h6>
                                                {/* <div className="mt-3 d-flex">
                                                    <button className="btn btn-light btn-sm mr-2" style={{backgroundColor: '#C0DFCA', color: '#4D895E'}}>Semua Transaksi</button>
                                                    <button className="btn btn-light btn-sm mr-2" style={{color: '#8C8C8C'}}>Top Up</button>
                                                    <button className="btn btn-light btn-sm mr-2" style={{color: '#8C8C8C'}}>Penarikan</button>
                                                    <button className="btn btn-light btn-sm mr-2" style={{color: '#8C8C8C'}}>Peminjaman</button>
                                                </div> */}
                                            </div>
                                            <div className="col-md-2" style={{paddingLeft: '5%'}}>
                                                <Datetime 
                                                    dateFormat="YYYY" 
                                                    timeFormat={false} 
                                                    value={this.state.currentYearTrans} 
                                                    closeOnSelect={true} 
                                                    onChange={this.handleYearTrans} 
                                                />
                                            </div>
                                        </div>
                                        <Transaction data={dataTransaksi} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div className="row mt-3">
                                            <div className="col-md-9">
                                                <h6>User dan Unit Usaha</h6>
                                            </div>
                                            <div className="col-md-2" style={{paddingLeft: '5%'}}>
                                                <Datetime 
                                                    dateFormat="YYYY" 
                                                    timeFormat={false} 
                                                    value={this.state.currentYear} 
                                                    closeOnSelect={true} 
                                                    onChange={this.handleYear} 
                                                />
                                            </div>
                                        </div>
                                        <UserUnit data={dataUserUnit} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Dashboard;
