import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { } from "../../store/actions";

// MetisMenu
import MetisMenu from "metismenujs";
import SimpleBar from "simplebar-react";

import { Link } from "react-router-dom";

const SidebarContent = props => {
    const role = localStorage.getItem('roleID')
    return (
        <>
            <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="menu-title">Menu</li>
                    {role !== null && role === "1" ? 
                        <>
                            <li>
                                <Link to="/dashboard" className="waves-effect">
                                    <i className="bx bxs-dashboard"></i>
                                    <span>Dashboard</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bxs-check-square"></i>
                                    <span>Verifikasi</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="identitas">Verifikasi Identitas</Link></li>
                                    <li><Link to="unit-usaha">Verifikasi Unit Usaha</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bxs-wallet-alt"></i>
                                    <span>Cash Flow</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="/riwayat-transaksi">Riwayat Transaksi</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bxs-wallet"></i>
                                    <span>Penarikan Dana</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="/penarikan-user">User</Link></li>
                                    <li><Link to="/penarikan-unitusaha">Unit Usaha</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bx-map-alt"></i>
                                    <span>Unit Usaha</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="/lokasi">Lokasi</Link></li>
                                    <li><Link to="/forum">Forum</Link></li>
                                    <li><Link to="/data-unit-usaha">Data Unit Usaha</Link></li>
                                </ul>
                            </li>
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bxs-group"></i>
                                    <span>User Management</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="/user-management">User List</Link></li>
                                    <li><Link to="/user-log">User Log</Link></li>
                                </ul>
                            </li>
                        </>
                    : 
                        <>
                            <li>
                                <Link to="/dashboard" className="waves-effect">
                                    <i className="bx bxs-dashboard"></i>
                                    <span>Dashboard</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/#" className="has-arrow waves-effect">
                                    <i className="bx bx-map-alt"></i>
                                    <span>Unit Usaha</span>
                                </Link>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><Link to="/lokasi">Lokasi</Link></li>
                                    <li><Link to="/forum">Forum</Link></li>
                                    <li><Link to="/data-unit-usaha">Data Unit Usaha</Link></li>
                                </ul>
                            </li>
                        </>
                    } 
                </ul>
            </div>
        </>
    )
};


class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        this.initMenu();
    }

    componentDidUpdate(prevProps) {
        if (this.props.type !== prevProps.type) {
            this.initMenu();
        }
    }

    initMenu() {
            new MetisMenu("#side-menu");

            var matchingMenuItem = null;
            var ul = document.getElementById("side-menu");
            var items = ul.getElementsByTagName("a");
            for (var i = 0; i < items.length; ++i) {
                if (this.props.location.pathname === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                this.activateParentDropdown(matchingMenuItem);
            }
        // }
    }

    activateParentDropdown = item => {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    render() {
        return (
            <React.Fragment>
                <div className="vertical-menu">
                    <div data-simplebar className="h-100">
                        {this.props.type !== "condensed" ? (
                            <SimpleBar style={{ maxHeight: "100%" }}>
                                <SidebarContent />
                            </SimpleBar>
                        ) : <SidebarContent />}
                    </div>

                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    return {
        layout: state.Layout
    };
};
export default connect(mapStatetoProps, {})(withRouter(Sidebar));
