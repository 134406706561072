import React, { Component } from "react";
import { Container, Card, CardBody, Modal, Row, Col } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getJwt } from "../../services/authService";
import httpService from "../../services/httpService";
import Swal from "sweetalert2";
import ReactDOM from "react-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import '../../assets/css/pagination.css';
//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import moment from "moment";

const url = process.env.REACT_APP_API_URL;

const priceSplitter = (number) =>
  number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

class Data_Unit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: localStorage.getItem("roleID"),
      modal_bills: false,
      unit_id: "",
      unit_name: "",
      nominal: "",
      type: "semar",
      desc: "",
      data_wallet: [],
      type_wallet: 'semar'
    };
    this.tog_bills = this.tog_bills.bind(this);
    this.tog_bills_close = this.tog_bills_close.bind(this);
    this.togTopUp = this.togTopUp.bind(this);
  }

  async getUnit() {
    const api_url = url + "unitUsaha";
    httpService
      .get(api_url, {
        headers: {
          Authorization: "Bearer " + getJwt(),
        },
      })
      .then((res) => {
        const response = res.data.data;
        const data = [];
        response.forEach((item, index) => {
          data.push([
            index + 1,
            item?.name,
            item?.admin[0]?.user?.profile?.full_name,
            "province" in item ? item?.province : '-',
            `Rp. ${
              item?.wallet_unit_usaha !== null &&
              item?.wallet_unit_usaha?.money !== 0
                ? priceSplitter(item?.wallet_unit_usaha?.money)
                : 0
            }`,
            `Rp. ${
              item?.celengan_semar !== null && item?.celengan_semar?.money !== 0
                ? priceSplitter(item?.celengan_semar?.money)
                : 0
            } `,
            item.id,
            item.activate,
          ]);
        });
        let showVisible = [];
        if (this.state.role === "3") {
          showVisible = [6];
        }
        $("#tableTrans").DataTable({
          destroy: true,
          lengthMenu: [
            [10, 15, -1],
            [10, 15, "All"],
          ],
          data: data,
          columnDefs: [
            { visible: false, targets: showVisible },
            {
              targets: 6,
              createdCell: (td, cellData, rowData, row, col) => {
                if (rowData[7] !== true) {
                  ReactDOM.render(
                    <div className="d-flex">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm waves-effect waves-light mr-2 mt-2"
                        onClick={() => this.tog_bills(rowData[6], rowData[1])}
                      >
                        <i className="bx bx-dollar"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-success btn-sm waves-effect waves-light mr-2 mt-2"
                        onClick={() => this.handleActivated(cellData, true)}
                      >
                        Active
                      </button>
                      <button
                        type="button"
                        disabled
                        className="btn btn-danger btn-sm waves-effect waves-light mr-2 mt-2"
                      >
                        Inactive
                      </button>
                    </div>,
                    td
                  );
                } else {
                  ReactDOM.render(
                    <div className="d-flex">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm waves-effect waves-light mr-2 mt-2"
                        onClick={() => this.tog_bills(rowData[6], rowData[1])}
                      >
                        <i className="bx bx-dollar"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-success btn-sm waves-effect waves-light mr-2 mt-2"
                        disabled
                      >
                        Active
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm waves-effect waves-light mr-2 mt-2"
                        onClick={() => this.handleActivated(cellData, false)}
                      >
                        Inactive
                      </button>
                    </div>,
                    td
                  );
                }
              },
            },
          ],
        });
      });
  }

  removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  tog_bills(id, name) {
    this.setState((prevState) => ({
      modal_bills: !prevState.modal_bills,
      unit_id: id,
      unit_name: name,
    }));
    const { type_wallet } = this.state
    this.getRekamJejak(id, type_wallet)
    this.removeBodyCss();
  }

  tog_bills_close() {
    this.setState({modal_bills: false})
    this.getUnit()
  }

  togTopUp(type) {
    const { unit_id } = this.state
    this.setState({type_wallet: type})
    this.getRekamJejak(unit_id, type)
  }

  handleActivated(id, key) {
    const api_url = url + "unitUsaha/active_inactive/" + id;
    const body = {
      activate: key,
    };
    let title = "";
    if (key !== true) {
      title = "Anda yakin akan menonaktifkan unit usaha ini?";
    } else {
      title = "Anda yakin akan mengaktifkan unit usaha ini?";
    }
    Swal.fire({
      title: title,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ya!",
      cancelButtonText: "Tidak!",
    }).then((result) => {
      if (result.isConfirmed) {
        httpService
          .put(api_url, body, {
            headers: {
              Authorization: "Bearer " + getJwt(),
            },
          })
          .then((res) => {
            this.getUnit();
            toast.success(res.data.message);
          })
          .catch((err) => {
            if (err.response) {
              toast.error(err.response.data.message);
            } else {
              toast.error("Network Error");
            }
          });
      }
    });
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleBill() {
    let url = process.env.REACT_APP_API_URL;
    const { unit_id, nominal, type, desc } = this.state;
    if (type !== "") {
      if (type === "semar") {
        url += "walletUnitUsaha/topupadmin-semar";
      } else {
        url += "walletUnitUsaha/topupadmin-asset";
      }
    }
    const body = {
      unit_usaha_id: unit_id,
      nominal: nominal,
      description: desc,
    };
    httpService
      .post(url, body, {
        headers: {
          Authorization: "Bearer " + getJwt(),
        },
      })
      .then((res) => {
        toast.success(res.data.message);
        this.getRekamJejak(unit_id, type);

      })
      .catch((err) => {
        if (err.response.status === 422) {
          toast.error(err.response.data.errors[0].message);
        } else if (err.response.status === 500) {
          toast.error("Network Error");
        }
      });
  }

  getRekamJejak(id, type) {
    let url = process.env.REACT_APP_API_URL;
    if (type === 'semar') {
      url +='unitUsaha/cashflow-semar/'+id+'?admin=1'
    } else {
      url += 'unitUsaha/cashflow/'+id+'?admin=1'
    }

    httpService.get(url, {
      headers: {
        Authorization: 'Bearer '+getJwt()
      }
    }).then(res => {
      const result = res.data.data;
      this.setState({data_wallet: result})
    })
  }

  componentDidMount() {
    $("#tableTrans").append(
      $("<tfoot/>").append($("#tableTrans thead tr").clone())
    );
    this.getUnit();
  }

  render() {
    const { data_wallet, type_wallet } = this.state
    return (
      <React.Fragment>
        <ToastContainer />
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Unit Usaha" breadcrumbItem="Data Unit Usaha" />
            <Card>
              <CardBody>
                <div className="mb-3">
                  <h5>List Unit Usaha</h5>
                </div>
                <div className="mt-3">
                  <table id="tableTrans" className="table w-100 responsive">
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Nama Unit Usaha</th>
                        <th>Nama Ketua</th>
                        <th>Lokasi</th>
                        <th>Celengan Bu Lurah</th>
                        <th>Celengan Semar</th>
                        <th>Aksi</th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Container>
          <Modal
            isOpen={this.state.modal_bills}
            toggle={this.tog_bills}
            className="modal-lg"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Transaksi
              </h5>
              <button
                type="button"
                onClick={() => this.tog_bills_close()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <Row>
                <Col>
                  <div className="form-group d-flex">
                    <label className="form-label col-md-3 mt-2 pl-0">
                      Nama Unit Usaha
                    </label>
                    <input
                      type="text"
                      name="unit_name"
                      value={this.state.unit_name}
                      className="form-control bg-light"
                      readOnly
                    ></input>
                  </div>
                  <div className="form-group d-flex">
                    <label className="form-label col-md-3 mt-2 pl-0">
                      Saldo Unit Usaha
                    </label>
                    <input
                      type="number"
                      name="nominal"
                      className="form-control"
                      onChange={(e) => this.handleChange(e)}
                      placeholder="Isi Saldo Unit Usaha"
                      required
                    ></input>
                  </div>
                  <div className="form-group d-flex">
                    <label className="form-label col-md-3 mt-2 pl-0">
                      Aktifitas
                    </label>
                    <select
                      name="type"
                      className="form-control"
                      onChange={(e) => this.handleChange(e)}
                      value={this.state.type}
                      required
                    >
                      <option value="semar">Top Up Semar</option>
                      <option value="asset">Top Up Asset</option>
                    </select>
                  </div>
                  <div className="form-group d-flex">
                    <label className="form-label col-md-3 mt-2 pl-0">
                      Keterangan
                    </label>
                    <textarea
                      type="text"
                      name="desc"
                      onChange={(e) => this.handleChange(e)}
                      className="form-control"
                      placeholder="Isi Keterangan"
                      required
                    ></textarea>
                  </div>
                </Col>
              </Row>
              <div className="text-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm"
                  onClick={() => this.handleBill()}
                >
                  Submit
                </button>
              </div>
              <div>
                <div className="d-flex justify-content-between mt-3">
                  <h6>Rekam Jejak {type_wallet.charAt(0).toUpperCase() + type_wallet.slice(1)} :</h6>
                  <div className="d-flex mb-2">
                    <div className={`btn  ${type_wallet === 'semar' ? 'btn-primary' : 'btn-light'} px-2 btn-sm`} onClick={() => this.togTopUp('semar')}>Semar</div>
                    <div className={`btn  ${type_wallet === 'assets' ? 'btn-primary' : 'btn-light'} px-2 btn-sm`} onClick={() => this.togTopUp('assets')}>Assets</div>
                  </div>
                </div>
                <div
                  style={{
                    border: "1px solid #52b06d",
                    padding: "10px",
                    borderRadius: "3px",
                    height: "15rem",
                    overflow: "scroll",
                    whiteSpace: "nowrap",
                    overflowX: "hidden",
                  }}
                >
                  <table className="table w-100" style={{ fontSize: "11px" }}>
                    <thead>
                      <tr>
                        <th>Tanggal dan Waktu</th>
                        <th>Aktifitas</th>
                        <th>Keterangan</th>
                        <th>Jumlah</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data_wallet.length > 0 ? (
                        data_wallet.map((item) => {
                          return (
                            <tr>
                              <td>{moment(item.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                              <td>{item.name}</td>
                              <td>{"description" in item ? item.description : '-'}</td>
                              <td className="text-primary">+ {item.nominal}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={4} className="text-center">
                            No Data Available!
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default Data_Unit;
