import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Modal } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { slideUp, slideDown } from '../../components/Animasi/anim';
import httpService from "../../services/httpService";
import { getJwt } from "../../services/authService";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

var idLocale = require('moment/locale/id'); 
moment.locale('id', idLocale);
var url = process.env.REACT_APP_API_URL

const styles = {
    tableBorder: {
        border: 'transparent',
        verticalAlign: 'transparent'
    }
}
class IdentitasTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            aksi: "Detail",
            modal_terima: false,
            modal_tolak: false,
            reason: ''
        };
        this.toggleExpander = this.toggleExpander.bind(this)
        this.toggleModalTerima = this.toggleModalTerima.bind(this)
        this.toggleModalTolak = this.toggleModalTolak.bind(this)
    }

    toggleExpander(e) {
        if (!this.state.expanded) {
            this.setState({expanded: true, aksi: "Tutup"}, () => {
                if (this.refs.expanderBody) {
                    slideDown(this.refs.expanderBody);
                }
            })
        } else {
            slideUp(this.refs.expanderBody, {
                onComplete: () => { this.setState({expanded: false, aksi: "Detail"}); }
            })
        }
    }

    toggleModalTerima() {
        this.setState(prevState => ({
          modal_terima: !prevState.modal_terima
        }));
    }

    toggleModalTolak() {
        this.setState(prevState => ({
          modal_tolak: !prevState.modal_tolak
        }));
    }

    handleChange(e) {
        this.setState({reason: e.target.value})
    }

    render() {
        const { data, handleReason, handleTerima, handleTolak } = this.props
        const date = moment(data.updated_at).format('LLLL'); 
        return [
            <tr key={data.id} onClick={this.toggleExpander}>
                <td>{data.full_name}</td>
                <td>{data.user.email}</td>
                <td>{data.phone}</td>
                <td>
                    <button className="btn btn-primary">{this.state.aksi}</button>
                </td>
            </tr>,
            this.state.expanded && (
                <tr className="expandable" key="tr-expander">
                    <td style={{backgroundColor: '#F6F6F6'}} colSpan="4">
                        <div ref="expanderBody">
                            <Row>
                                <Col md="3">
                                    <img src={url + data.ktp} alt="" className="w-100" style={{ height: '15rem', objectFit: 'contain' }} />
                                </Col>
                                <Col md="3">
                                    <img src={url + data.swafoto_ktp} alt="" className="w-100" style={{ height: '15rem', objectFit: 'contain' }} />
                                </Col>
                                <Col md="6">
                                    <table className="w-100">
                                        <thead>
                                            <tr>
                                                <td style={{ width: '35%'}}>Nama</td>
                                                <th style={styles.tableBorder}>{data.full_name}</th>
                                            </tr>
                                            <tr>
                                                <td>NIK</td>
                                                <th style={styles.tableBorder}>{data.nomer_ktp}</th>
                                            </tr>
                                            <tr>
                                                <td>Waktu Permintaan</td>
                                                <th style={styles.tableBorder}>{date}</th>
                                            </tr>
                                        </thead>
                                    </table>
                                    <div className="mt-3">
                                        <p><b>Terima Permintaan Verifikasi?</b></p>
                                        <div className="d-flex">
                                            <button className="btn btn-danger mr-2" onClick={this.toggleModalTolak}>Tolak</button>
                                            <button className="btn btn-primary mr-2" onClick={this.toggleModalTerima}>Terima</button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Modal
                                isOpen={this.state.modal_terima}
                                toggle={this.toggleModalTerima}
                                >
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0" id="myModalLabel">
                                    Terima Permintaan Verifikasi
                                </h5>
                                    <button
                                    type="button"
                                    onClick={() =>
                                        this.setState({ modal_terima: false })
                                    }
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    >
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <p>Anda akan menerima permintaan verifikasi, pastikan data yang diberikan sesuai dengan syarat dan ketentuan yang berlaku</p>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-danger waves-effect"
                                        data-dismiss="modal"
                                        onClick={this.toggleModalTerima}
                                    >
                                        Batal
                                    </button>
                                    <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => {handleTerima(data.user_id, true); this.toggleModalTerima()}}
                                    >
                                        Terima
                                    </button>
                                </div>
                            </Modal>
                            <Modal
                                isOpen={this.state.modal_tolak}
                                toggle={this.toggleModalTolak}
                                >
                                <div className="modal-header">
                                    <h5 className="modal-title mt-0" id="myModalLabel">
                                    Tolak Permintaan Verifikasi
                                </h5>
                                    <button
                                    type="button"
                                    onClick={() =>
                                        this.setState({ modal_tolak: false })
                                    }
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    >
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <label className="form-label">Alasan Penolakan</label>
                                        <textarea className="form-control" value={this.state.reason} onChange={(e) => {handleReason(e); this.setState({reason: e.target.value})}}></textarea>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-danger waves-effect"
                                        data-dismiss="modal"
                                        onClick={this.toggleModalTolak}
                                    >
                                        Batal
                                    </button>
                                    <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light"
                                    onClick={() => {handleTolak(data.user_id, false, this.state.reason); this.toggleModalTolak()}}
                                    >
                                        Kirim
                                    </button>
                                </div>
                            </Modal>
                        </div>
                    </td>
                </tr>
            )
        ]
    }
}

class Identitas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            identitas: [],
            verif: [true, false],
            reason: ''
        };
        this.getData = this.getData.bind(this);
    }

    async getData() {
        const apiUrl = url + 'user/get_verification'
        httpService.get(apiUrl, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            this.setState({identitas: res.data.data})
        })
    }

    handleChange(e) {
        this.setState({reason: e.target.value})
    }

    verifTerima(id, verif) {
        const apiUrl = url + 'user/verification'
        const obj = {
            user_id: id,
            verification: verif
        }
        httpService.post(apiUrl, obj, {
            headers: {
                Authorization: "Bearer "+getJwt()
            }
        })
        .then((res) => {
            toast.success(res.data.message);
            this.getData();
        })
        .catch(() => {
            toast.error('Network Error !!!')
        })
    }

    verifTolak(id, verif, reason) {
        const apiUrl = url + 'user/verification'
        const obj = {
            user_id: id,
            verification: verif,
            notes: reason
        }

        httpService.post(apiUrl, obj, {
            headers: {
                Authorization: "Bearer "+getJwt()
            }
        })
        .then((res) => {
            toast.success(res.data.message);
            this.getData();
        })
        .catch(() => {
            toast.error('Network Error !!!')
        })
    }

    async componentDidMount() {
        await this.getData()
    }

    render() {  
        const { identitas } = this.state
        return (
            <React.Fragment>
                <ToastContainer />
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Verifikasi" breadcrumbItem="Verifikasi Identitas" />
                        <Card>
                            <CardBody>
                                <h6>Verifikasi Identitas List</h6>
                                <div className="text-right">
                                    <Link to={"/riwayat-identitas"}>
                                        <button className="btn btn-primary">Riwayat</button>
                                    </Link>
                                </div>
                                <div className="table-responsive mt-3">
                                    <table className="table w-100">
                                        <thead>
                                            <tr>
                                                <th>Nama</th>
                                                <th>Email</th>
                                                <th>No Tlp</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {identitas.length > 0 ? (
                                                identitas.map((i, index) => <IdentitasTable key={index} data={i} handleReason={(e) => this.handleChange(e)} handleTerima={() => this.verifTerima(i.id,true)} handleTolak={() => this.verifTolak(i.id, false, this.state.reason)} />)
                                            ): (
                                                <tr>
                                                    <td colSpan="4" className="text-center">No Data Available</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default Identitas;