import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Modal } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "../../assets/scss/datatables.scss";
import { MDBDataTable } from "mdbreact";
import httpService from "../../services/httpService";
import { getJwt } from "../../services/authService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g,'.'));

class PenarikanUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_standard: false,
            choose: 'Choose File',
            all: [],
            status: 'all',
            data: {
                columns: [
                    {
                        label: "Nama User",
                        field: "nama_user",
                        sort: "asc",
                        width: 150
                    },
                    {
                        label: "Jumlah Penarikan",
                        field: "jml_penarikan",
                        sort: "asc",
                        width: 270
                    },
                    {
                        label: "Nama Bank",
                        field: "bank_name",
                        sort: "asc",
                        width: 200
                    },
                    {
                        label: "No Rekening",
                        field: "no_rek",
                        sort: "asc",
                        width: 100
                    },
                    {
                        label: "Pemilik Rekening",
                        field: "pemilik_rek",
                        sort: "asc",
                        width: 150
                    },
                    {
                        label: "Status",
                        field: "status",
                        sort: "asc",
                        width: 150
                    },
                    {
                        label: "",
                        field: "aksi",
                        sort: "asc",
                        width: 100
                    }
                ],
                rows: []
            }
        };
        this.tog_standard = this.tog_standard.bind(this);
        this.handleFile = this.handleFile.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.submitFilter = this.submitFilter.bind(this);
    }

    async getData() {
        const apiUrl = process.env.REACT_APP_API_URL + 'withdrawal/user';
        httpService.get(apiUrl, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const data = res.data.data;
            data.sort((a,b) => {
                const dateA = new Date(a.updated_at);
                const dateB = new Date(b.updated_at);
                return dateB - dateA
            })
            this.setState({ all: data })
            this.getTable(data)
        })
        .catch(() => {
            toast.error('Network error!');
        })
    }

    getTable(data) {
        const rows = []
        data.forEach(item => {
            let btn;
            if (item.status === 'pending') {
                btn = <button type="button" onClick={() => this.tog_standard(item)} className="btn btn-primary waves-effect waves-light" data-toggle="modal" data-target="#myModal">Proses Dana</button>
            } else {
                btn = <button type="button" className="btn btn-primary waves-effect waves-light" disabled >Proses Dana</button>
            }
            console.log(item)
            rows.push({
                nama_user: item?.profile?.full_name,
                jml_penarikan: 'Rp. '+priceSplitter(item?.nominal),
                bank_name: item?.bank_name,
                no_rek: item?.no_rekening,
                pemilik_rek: item?.nama_rekening,
                status: item?.status,
                aksi: btn
            })
        })
        this.setState({
            data: {
                ...this.state.data,
                rows: rows
            }
        })

    }

    removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    tog_standard(data) {
        const obj = 
            <table className="w-100">
                <thead>
                    <tr>
                        <td className="text-muted" style={{padding: '0.5rem', width: '35%'}}>Nama User</td>
                        <th>{data?.profile?.full_name}</th>
                    </tr>
                    <tr>
                        <td className="text-muted" style={{padding: '0.5rem'}}>Total Penarikan</td>
                        <th>Rp. {priceSplitter(data?.nominal)}</th>
                    </tr>
                    <tr>
                        <td className="text-muted" style={{padding: '0.5rem'}}>Bank</td>
                        <th>{data?.bank_name}</th>
                    </tr>
                    <tr>
                        <td className="text-muted" style={{padding: '0.5rem'}}>No Rekening</td>
                        <th>{data?.no_rekening}</th>
                    </tr>
                    <tr>
                        <td className="text-muted" style={{padding: '0.5rem'}}>Pemilik Rekening</td>
                        <th>{data?.nama_rekening}</th>
                    </tr>
                </thead>
                <tbody></tbody>
            </table>
        this.setState(prevState => ({
          modal_standard: !prevState.modal_standard,
          id: data.id,
          obj
        }));
        this.removeBodyCss();
    }

    handleFile(e) {
        if (e !== null){
            if (e.target.files[0].size > 2097152) {
            toast.warn('File tidak boleh melebihi 2MB', {
                position: 'top-center'
            })
            this.setState({ announcement: 'Silakan upload ulang file anda!'})
            } else {
                this.setState({ file: e.target.files[0], choose: e.target.files[0].name, announcement: '' })
            }
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL + 'withdrawal/user_transaction?id='+this.state.id
        const formData = new FormData();
        formData.append('id', this.state.id);
        formData.append('status', 'success');
        formData.append('photo_payment', this.state.file);
        httpService.post(apiUrl, formData, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            this.setState({ modal_standard: false })
            toast.success(res.data.message);
            this.getData();
        })
        .catch(err => {
            if (err.response) {
                toast.error(err.response.data.errors[0].message);
            } else {
                toast.error('Network Error !!!');
            }
        })
    }
    
    handleReject(e) {
        e.preventDefault();
        const apiUrl = process.env.REACT_APP_API_URL + 'withdrawal/user_transaction?id='+this.state.id
        const formData = new FormData();
        formData.append('id', this.state.id);
        formData.append('status', 'rejected');
        httpService.post(apiUrl, formData, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            this.setState({ modal_standard: false })
            toast.success(res.data.message);
            this.getData();
        })
        .catch(err => {
            if (err.response) {
                toast.error(err.response.data.errors[0].message);
            } else {
                toast.error('Network Error !!!');
            }
        })
    }

    handleFilter(e) {
        const value = e.target.value;
        this.setState({ status: value })
    }

    submitFilter() {
        const { all, status } = this.state
        const data_filter = [];
        if (status === "pending") {
            all.forEach(item => {
                if (item.status === "pending") {
                    data_filter.push(item)
                }
            })
        } else if (status === "success") {
            all.forEach(item => {
                if (item.status === "success") {
                    data_filter.push(item)
                }
            })
        } else if (status === "rejected") {
            all.forEach(item => {
                if (item.status === "rejected") {
                    data_filter.push(item)
                }
            })
        } else {
            all.forEach(item => {
                data_filter.push(item)
            })
        }
        this.getTable(data_filter)
    }

    async componentDidMount() {
        await this.getData();
    }

    render() { 
        const { data, obj, choose, announcement } = this.state
        return (
            <React.Fragment>
                <ToastContainer />
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Penarikan Dana" breadcrumbItem="User" />
                        <Card>
                            <CardBody>
                                <div className="mb-3">
                                    <h5>Perminataan Penarikan Dana</h5>
                                </div>
                                <Row>
                                    <Col md="3">
                                        <div>
                                            <select className="form-control" onChange={this.handleFilter}>
                                                <option value="all">Semua Status</option>
                                                <option value="pending">Pending</option>
                                                <option value="success">Success</option>
                                                <option value="rejected">Rejected</option>
                                            </select>
                                        </div>
                                    </Col>
                                    <Col>
                                        <button className="btn btn-primary" onClick={this.submitFilter}>Apply</button>
                                    </Col>
                                </Row>
                                <div className="mt-3">
                                    <MDBDataTable responsive data={data} />
                                </div>
                                <Modal
                                    isOpen={this.state.modal_standard}
                                    toggle={this.tog_standard}
                                    >
                                    <div className="modal-header">
                                        <h5 className="modal-title mt-0" id="myModalLabel">
                                        Perminataan Penarikan Dana
                                    </h5>
                                        <button
                                        type="button"
                                        onClick={() =>
                                            this.setState({ modal_standard: false })
                                        }
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        >
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="table-responsive">
                                            {obj}
                                            <div className="form-group mt-3" style={{padding: '0.5rem'}}>
                                                <label className="form-label">Bukti Pembayaran</label>
                                                <div className="custom-file">
                                                    <input type="file" className="custom-file-input" id="customFile" onChange={this.handleFile} />
                                                    <label className="custom-file-label" htmlFor="customFile">{choose}</label>
                                                </div>
                                                <span className="text-danger">{announcement}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-danger waves-effect"
                                            onClick={(e) => this.handleReject(e)}
                                        >
                                            Tolak
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary waves-effect waves-light"
                                            onClick={(e) => this.handleSubmit(e)}
                                        >
                                            Terima
                                        </button>
                                    </div>
                                </Modal>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default PenarikanUser;