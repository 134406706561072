import JwtDecode from "jwt-decode";
import http from "./httpService";
// import { apiUrl } from "../config.json";
import { BehaviorSubject } from "rxjs";
import dotenv from "dotenv";

dotenv.config()
const apiEndpoint = process.env.REACT_APP_API_URL + "auth/login";
const tokenKey = "token";
const role = "roleID";
const currentUserSubject = new BehaviorSubject(getCurrentUser());


http.setJwt(getJwt());

export async function login(email, password) {
    const {data: jwt} = await http.post(apiEndpoint, {email, password})
    // const {data: jwt} = {email, password}
    localStorage.setItem(tokenKey, jwt.data.token);
    localStorage.setItem(role, jwt.user.user_role);
    // localStorage.setItem(tokenKey, "NjY.nuvcSl54UF1d2L73st3iwU01L2-b0-Fm8M5NLdKD_Z4SOg-aQ37AmnGwbwJD");
    currentUserSubject.next(getCurrentUser());
}

export function loginWithJwt(jwt) {
    localStorage.setItem(tokenKey, jwt);
}

export function logout() {
    localStorage.removeItem(tokenKey);
    localStorage.removeItem(role);
    currentUserSubject.next(null);
}

export function getCurrentUser() {
    try {
        const jwt = localStorage.getItem("token");
        return JwtDecode(jwt);
    } catch (ex) {
        return null;
    }
}

export function getJwt() {
    return localStorage.getItem(tokenKey);
}

export function subCurrentUser() {
    logout()
    return currentUserSubject.asObservable()
}

export default {
    login,
    loginWithJwt,
    logout,
    getCurrentUser,
    getJwt,
    subCurrentUser
};