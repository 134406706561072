import React, { Component } from "react";
import { Container, Card, CardBody } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import httpService from "../../services/httpService";
import { getJwt } from "../../services/authService";
import { MDBDataTable } from "mdbreact";
import "../../assets/scss/datatables.scss";

var url = process.env.REACT_APP_API_URL

class RiwayatUnit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            history: {
                columns: [
                    {
                        label: "Nama Unit Usaha",
                        field: "nama_unit",
                        sort: "asc",
                        width: 150
                    },
                    {
                        label: "Level",
                        field: "level",
                        sort: "asc",
                        width: 150
                    },
                    {
                        label: "Ketua Unit Usaha",
                        field: "ketua",
                        sort: "asc",
                        width: 150
                    },
                    {
                        label: "Status",
                        field: "status",
                        sort: "asc",
                        width: 150
                    },
                    {
                        label: "Note",
                        field: "note",
                        sort: "asc",
                        width: 150
                    },
                ],
                rows: []
            }
        };
        this.handleFilter = this.handleFilter.bind(this);
    }

    async getData() {
        const apiUrl = url + 'history/unitusaha_verification'
        httpService.get(apiUrl, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const response = res.data.data
            this.setState({ data: response })
            this.showData(response)
        })
    }

    handleFilter(e) {
        const val = e.target.value;
        const rowsFilter = [];
        this.state.data.forEach(item => {
            let statusA;
            let statusB;
            let status;
            if (item.status === 'accepted') {
                status = <span className="font-size-12 badge badge-soft-success rounded-pill">Diterima</span>
                statusA = <span className="font-size-12 badge badge-soft-success rounded-pill">{val}</span>
            } else {
                status = <span className="font-size-12 badge badge-soft-danger rounded-pill">Ditolak</span>
                statusB = <span className="font-size-12 badge badge-soft-danger rounded-pill">{val}</span>
            }
            if (val === "Diterima") {
                if (item.status === 'accepted')
                rowsFilter.push({
                    nama_unit: item.unit_usaha.name,
                    level: item.level,
                    ketua: item.unit_usaha.member[0].user.profile.full_name,
                    status: statusA,
                    note: item.note
                })
            } else if (val === "Ditolak") {
                if (item.status === "rejected")
                rowsFilter.push({
                    nama_unit: item.unit_usaha.name,
                    level: item.level,
                    ketua: item.unit_usaha.member[0].user.profile.full_name,
                    status: statusB,
                    note: item.note
                })
            } else {
                rowsFilter.push({
                    nama_unit: item.unit_usaha.name,
                    level: item.level,
                    ketua: item.unit_usaha.member[0].user.profile.full_name,
                    status: status,
                    note: item.note
                })
            }
        })
        this.setState({
            history: {
                ...this.state.history,
                rows: rowsFilter
            }
        })
    }

    showData(data) {
        const newRows = [];
        data.forEach(item => {
            let status;
            if (item.status === "accepted") {
                status = <span className="font-size-12 badge badge-soft-success rounded-pill">Diterima</span>
            } else {
                status = <span className="font-size-12 badge badge-soft-danger rounded-pill">Ditolak</span>
            }
            newRows.push({
                nama_unit: item.unit_usaha.name,
                level: item.level,
                ketua: item.unit_usaha.member[0].user.profile.full_name,
                status: status,
                note: item.note
            })
        })
        this.setState({
            history: {
                ...this.state.history,
                rows: newRows
            }
        })
    }

    async componentDidMount() {
        await this.getData()
    }

    render() {  
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Verifikasi" breadcrumbItem="Verifikasi Unit usaha" />
                        <Card>
                            <CardBody>
                                <h6>Riwayat Verifikasi Unit Usaha List</h6>
                                <div className="d-flex mt-3">
                                    <span className="mt-2 mr-3">Filter Status</span>
                                    <select className="form-control col-md-2" onChange={(e) => this.handleFilter(e)}>
                                        <option value="all">All</option>
                                        <option value="Diterima">Diterima</option>
                                        <option value="Ditolak">Ditolak</option>
                                    </select>
                                </div>
                                <div className="mt-3">
                                    <MDBDataTable responsive data={{columns: this.state.history.columns, rows : this.state.history.rows}} />
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default RiwayatUnit;