import React, { Component } from "react";
import { Container, Card, CardBody } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { MDBDataTable } from "mdbreact";
import { getJwt } from "../../services/authService";
import httpService from "../../services/httpService";
import moment from 'moment';

const url = process.env.REACT_APP_API_URL

class User_Log extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                columns: [
                    {
                        label: 'No',
                        field: 'no',
                    },
                    {
                        label: 'IP Address',
                        field: 'ip_address',
                        sort: 'asc',
                    },
                    {
                        label: 'Email',
                        field: 'email',
                        sort: 'asc',
                    },
                    {
                        label: 'Aktifitas',
                        field: 'activity',
                        sort: 'asc',
                    },
                    {
                        label: 'Tanggal dan Waktu',
                        field: 'tgl',
                        sort: 'asc',
                    }
                ],
                rows: []
            }
        }
    }

    async getLog() {
        const api_url = url + 'userlog_activity'
        httpService.get(api_url, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const response = res.data.data
            console.log(response)
            const row = [];
            response.forEach((item, index) => {
                row.push({
                    no: index+1,
                    ip_address: item.ip_address,
                    email: item.email,
                    activity: item.activity,
                    tgl: moment(item.updated_at).format('YYYY-MM-DD HH:mm:ss'),
                })
            })
            this.setState({
                data: {
                    ...this.state.data,
                    rows: row
                }
            })
        })
    }

    componentDidMount() {
        this.getLog()
    }

    render() { 
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="User Management" breadcrumbItem="User Log" />
                        <Card>
                            <CardBody>
                            <div className="mb-3">
                                    <h5>List User Log</h5>
                                </div>
                                <div className="mt-3">
                                    <MDBDataTable responsive data={this.state.data} />
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default User_Log;