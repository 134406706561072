import React, { Component } from "react";
import { Container, Card, CardBody } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import httpService from "../../services/httpService";
import { getJwt } from "../../services/authService";
import { MDBDataTable } from "mdbreact";
import "../../assets/scss/datatables.scss";

var url = process.env.REACT_APP_API_URL

class RiwayatIdentitas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            history: {
                columns: [
                    {
                        label: "Nama",
                        field: "nama",
                        sort: "asc",
                        width: 150
                    },
                    {
                        label: "Email",
                        field: "email",
                        sort: "asc",
                        width: 150
                    },
                    {
                        label: "No Tlp",
                        field: "no_tlp",
                        sort: "asc",
                        width: 150
                    },
                    {
                        label: "Status",
                        field: "status",
                        sort: "asc",
                        width: 150
                    },
                ],
                rows: []
            }
        };
        this.handleFilter = this.handleFilter.bind(this);
    }

    async getData() {
        const apiUrl = url + 'history/user_verification'
        httpService.get(apiUrl, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const response = res.data.data
            this.setState({ data: response })
            this.showData(response)
        })
    }

    handleFilter(e) {
        const val = e.target.value;
        const rowsFilter = [];
        this.state.data.forEach((item, index) => {
            let statusA;
            let statusB;
            let status;
            if (item.verification === true) {
                status = <span className="font-size-12 badge badge-soft-success rounded-pill">Diterima</span>
                statusA = <span className="font-size-12 badge badge-soft-success rounded-pill">{val}</span>
            } else {
                status = <span className="font-size-12 badge badge-soft-danger rounded-pill">Ditolak</span>
                statusB = <span className="font-size-12 badge badge-soft-danger rounded-pill">{val}</span>
            }
            if (val === "Diterima") {
                if (item.verification === true)
                rowsFilter.push({
                    nama: item.full_name,
                    email: item.user.email,
                    no_tlp: item.phone,
                    status: statusA
                })
            } else if (val === "Ditolak") {
                if (item.verification === false)
                rowsFilter.push({
                    nama: item.full_name,
                    email: item.user.email,
                    no_tlp: item.phone,
                    status: statusB
                })
            } else {
                rowsFilter.push({
                    nama: item.full_name,
                    email: item.user.email,
                    no_tlp: item.phone,
                    status: status
                })
            }
        })
        this.setState({
            history: {
                ...this.state.history,
                rows: rowsFilter
            }
        })
    }

    showData(data) {
        const newRows = [];
        data.forEach((item, index) => {
            let status;
            if (item.verification === true) {
                status = <span className="font-size-12 badge badge-soft-success rounded-pill">Diterima</span>
            } else {
                status = <span className="font-size-12 badge badge-soft-danger rounded-pill">Ditolak</span>
            }
            newRows.push({
                nama: item.full_name,
                email: item.user.email,
                no_tlp: item.phone,
                status: status
            })
        })
        this.setState({
            history: {
                ...this.state.history,
                rows: newRows
            }
        })
    }

    async componentDidMount() {
        await this.getData()
    }

    render() {  
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Verifikasi" breadcrumbItem="Verifikasi Identitas" />
                        <Card>
                            <CardBody>
                                <h6>Riwayat Verifikasi Identitas List</h6>
                                <div className="d-flex mt-3">
                                    <span className="mt-2 mr-3">Filter Status</span>
                                    <select className="form-control col-md-2" onChange={(e) => this.handleFilter(e)}>
                                        <option value="all">All</option>
                                        <option value="Diterima">Diterima</option>
                                        <option value="Ditolak">Ditolak</option>
                                    </select>
                                </div>
                                <div className="mt-3">
                                    <MDBDataTable responsive data={{columns: this.state.history.columns, rows : this.state.history.rows}} />
                                </div>
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default RiwayatIdentitas;