import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import io from 'socket.io-client'
import { getJwt } from "../../../services/authService";
import httpService from "../../../services/httpService";
import SimpleBar from "simplebar-react";
import moment from 'moment';

const ENDPOINT = process.env.REACT_APP_API_URL
let socket;

class NotificationDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu: false,
      getSocket: [],
      all_data: [],
      verif_user: [],
      verif_unit: [],
      withdrawl: [],
      date_verif1: '',
      date_withdrawl: '',
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState(prevState => ({
      menu: !prevState.menu
    }));
  }

  async getData() {
    const apiUrl = ENDPOINT + 'notification?limit=10'
    httpService.get(apiUrl, {
      headers: {
        Authorization: 'Bearer '+getJwt()
      }
    })
    .then(res => {
      const data  = res.data.data
      const verif_user = data.user_verification
      const verif_unit = data.unit_usaha_upgrade
      const withdrawl = data.unit_usaha_withdrawal
      if (verif_unit.length > 0) {
        const dt = new Date(verif_unit[0].updated_at)
        const date = moment(dt, 'YYYYMMDD').fromNow()
        const dt_withdrawl = withdrawl.length !== 0 ? new Date(withdrawl[0].updated_at) : ""
        const date_withdrawl = moment(dt_withdrawl, 'YYYYMMDD').fromNow()
        this.setState({all_data: data, verif_user, verif_unit, withdrawl, date_verif1: date, date_withdrawl })
      }
    })
  }

  componentDidMount() {
    socket = io(ENDPOINT, {path:'/ws'})
    socket.on('dashboard_admin_notif', (data) => {
      this.setState({
        getSocket: [data]
      })
    })
    this.getData()
  }

  render() {
    const { getSocket, verif_user, verif_unit, withdrawl, date_verif1, date_withdrawl } = this.state
    return (
      <React.Fragment>

        <Dropdown
          isOpen={this.state.menu}
          toggle={this.toggle}
          className="dropdown d-inline-block"
          tag="li"
        >
          <DropdownToggle
            className="btn header-item noti-icon waves-effect"
            tag="button" id="page-header-notifications-dropdown">
            {getSocket.length > 0 ? (
              <div>
                <i className="bx bx-bell bx-tada"></i>
                <span className="badge badge-danger badge-pill">*</span>
              </div>
            ) : (
              <i className="bx bx-bell"></i>
            )}
          </DropdownToggle>

          <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0" right>
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0"> Notifications </h6>
                </Col>
                {/* <div className="col-auto">
                  <a href="#!" className="small"> View All</a>
                </div> */}
              </Row>
            </div>
            
            {verif_user.length === 0 && verif_unit.length === 0 && withdrawl.length === 0 ? (
              <SimpleBar style={{ height: "60px" }}>
                <Link to="#" className="text-reset notification-item">
                  <div className="media">
                    <div className="media-body text-center">
                      <h6 className="mt-0 mb-1">Tidak ada Notifikasi</h6>
                    </div>
                  </div>
                </Link>
              </SimpleBar>
            ) : (
              <div>
                <SimpleBar style={{ height: "230px" }}>
                {verif_user.length === 1 ? (
                  <Link to="/identitas" className="text-reset notification-item">
                    <div className="media">
                      <div className="avatar-xs mr-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          <i className="bx bxs-check-square"></i>
                        </span>
                      </div>
                      <div className="media-body">
                        <h6 className="mt-0 mb-1">Verifikasi User</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1"> {verif_user[0].name} meminta untuk verifikasi akun</p>
                          <p className="mb-0"><i className="mdi mdi-clock-outline"></i> {date_verif1}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div></div>
                )}

                {verif_user.length > 1 ? (
                  <Link to="/identitas" className="text-reset notification-item">
                    <div className="media">
                      <div className="avatar-xs mr-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          <i className="bx bxs-check-square"></i>
                        </span>
                      </div>
                      <div className="media-body">
                        <h6 className="mt-0 mb-1">Verifikasi User</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1"> {verif_user[0].name} dan {verif_user.length - 1} user lainnya, meminta untuk verifikasi akun</p>
                          <p className="mb-0"><i className="mdi mdi-clock-outline"></i> {date_verif1}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div></div>
                )}

                {verif_unit.length === 1 ? (
                  <Link to="/unit-usaha" className="text-reset notification-item">
                    <div className="media">
                      <div className="avatar-xs mr-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          <i className="bx bxs-check-square"></i>
                        </span>
                      </div>
                      <div className="media-body">
                        <h6 className="mt-0 mb-1">Verifikasi Unit Usaha</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1"> {verif_unit[0].name} meminta untuk upgrade unit usaha</p>
                          <p className="mb-0"><i className="mdi mdi-clock-outline"></i> {date_verif1}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div></div>
                )}

                {verif_unit.length > 1 ? (
                  <Link to="/unit-usaha" className="text-reset notification-item">
                    <div className="media">
                      <div className="avatar-xs mr-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          <i className="bx bxs-check-square"></i>
                        </span>
                      </div>
                      <div className="media-body">
                        <h6 className="mt-0 mb-1">Verifikasi Unit Usaha</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1"> {verif_unit[0].name} dan {verif_unit.length - 1} unit usaha lainnya, meminta untuk upgrade unit usaha</p>
                          <p className="mb-0"><i className="mdi mdi-clock-outline"></i> {date_verif1}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div></div>
                )}

                {withdrawl.length === 1 ? (
                  <Link to="/unit-usaha" className="text-reset notification-item">
                    <div className="media">
                      <div className="avatar-xs mr-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          <i className="bx bxs-check-square"></i>
                        </span>
                      </div>
                      <div className="media-body">
                        <h6 className="mt-0 mb-1">Penarikan Dana</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1"> {withdrawl[0].unit_usaha.name} meminta penarikan dana sebesar {withdrawl[0].nominal}</p>
                          <p className="mb-0"><i className="mdi mdi-clock-outline"></i> {date_withdrawl}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div></div>
                )}              

                {withdrawl.length > 1 ? (
                  <Link to="/penarikan" className="text-reset notification-item">
                    <div className="media">
                      <div className="avatar-xs mr-3">
                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                          <i className="bx bxs-wallet-alt"></i>
                        </span>
                      </div>
                      <div className="media-body">
                        <h6 className="mt-0 mb-1">Penarikan Dana</h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1"> {withdrawl[0].unit_usaha.name} dan {withdrawl.length - 1} unit usaha lainnya, meminta penarikan dana</p>
                          <p className="mb-0"><i className="mdi mdi-clock-outline"></i> {date_withdrawl}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <div></div>
                )}

              </SimpleBar>
              <div className="p-2 border-top">
                <Link
                  className="btn btn-sm btn-link font-size-14 btn-block text-center"
                  to="/history"
                >
                  {" "}
                  View all{" "}
                </Link>
              </div>
            </div>
            )}
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}
export default NotificationDropdown;
