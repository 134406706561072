import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { Container, Card, CardBody } from "reactstrap";
import Breadcrumbs from '../../Common/Breadcrumb';
import httpService from "../../../services/httpService";
import { getJwt } from "../../../services/authService";
import moment from 'moment';

var url = process.env.REACT_APP_API_URL

class History extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        };
    }

    async getData() {
        const apiUrl = url + 'notification'
        httpService.get(apiUrl, {
            headers: {
                Authorization: 'Bearer '+getJwt()
            }
        })
        .then(res => {
            const response = res.data.data
            const all_data = []
            response.unit_usaha_upgrade.forEach(unit => {
                const date = moment(unit.updated_at, 'YYYYMMDD').fromNow()
                all_data.push({
                    name: "Verifikasi Unit Usaha",
                    member: unit.name,
                    date: date,
                    link: '/unit-usaha',
                    desc: 'meminta untuk upgrade unit usaha',
                    icon: 'bx bxs-check-square'
                })
            })
            response.unit_usaha_withdrawal.forEach(unit => {
                const date = moment(unit.updated_at, 'YYYYMMDD').fromNow()
                all_data.push({
                    name: "Penarikan Dana",
                    member: unit.unit_usaha.name,
                    date: date,
                    link: '/penarikan',
                    desc: 'meminta penarikan dana sebesar '+unit.nominal,
                    icon: 'bx bxs-wallet-alt'
                })
            })
            response.user_verification.forEach(user => {
                const date = moment(user.updated_at, 'YYYYMMDD').fromNow()
                all_data.push({
                    name: "Verifikasi User",
                    mamber: user.name,
                    date: date,
                    link: '/identitas',
                    desc: 'meminta untuk verifikasi akun',
                    icon: 'bx bxs-check-square'
                })
            })
            this.setState({ data: all_data })
        })
    }

    async componentDidMount() {
        await this.getData()
    }

    render() {  
        const { data } = this.state
        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Notifikasi" breadcrumbItem="History Notifikasi" />
                        <Card>
                            <CardBody>
                                {data.map(item => 
                                    <Link to={item.link} className="text-reset notification-item">
                                    <div className="media">
                                      <div className="avatar-xs mr-3">
                                        <span className="avatar-title bg-primary rounded-circle font-size-16">
                                          <i className={item.icon}></i>
                                        </span>
                                      </div>
                                      <div className="media-body">
                                        <h6 className="mt-0 mb-1">{item.name}</h6>
                                        <div className="font-size-12 text-muted">
                                          <p className="mb-1"> {item.member} meminta penarikan dana sebesar {item.desc}</p>
                                          <p className="mb-0"><i className="mdi mdi-clock-outline"></i> {item.date}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </Link>
                                )}
                            </CardBody>
                        </Card>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

export default History;